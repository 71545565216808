// jshint esversion:6

import "bootstrap";
import "../sass/main.scss";
import "./slick.min";
import "sweetalert";
import "./carousel";
import "./jqueryEasing";
import "./herma";
import "./requestForm";
import "./contactForm";
