import * as fs from "./functions";

// Request Form
$(document).ready(function () {
  if (document.forms.contactForm) {
    const contactForm = document.forms.contactForm,
      visitorName = contactForm["visitor_name"],
      visitorComName = contactForm["visitor_com_name"],
      visitorEmail = contactForm["visitor_email"],
      visitorReason = contactForm["visitor_reason"],
      visitorPhone = contactForm["visitor_phone"],
      visitorMessage = contactForm["visitor_msg"],
      btnSend = contactForm["send_contact"];

    // Prevent Default
    contactForm.addEventListener("submit", function (e) {
      e.preventDefault();
    });

    // BEGIN VALIDATION

    // Validate Name
    visitorName.addEventListener("blur", fs.validateName);
    visitorName.addEventListener("change", fs.validateName);

    // Validate Email
    visitorEmail.addEventListener("blur", fs.validateEmail);
    visitorEmail.addEventListener("change", fs.validateEmail);

    // Validate Reason
    visitorReason.addEventListener("blur", fs.validateReason);
    visitorReason.addEventListener("change", fs.validateReason);

    // Validate Company Name
    visitorComName.addEventListener("blur", fs.validateComName);
    visitorComName.addEventListener("change", fs.validateComName);

    // Validate Phone
    visitorPhone.addEventListener("blur", fs.validatePhone);
    visitorPhone.addEventListener("change", fs.validatePhone);

    // Validate Message
    visitorMessage.addEventListener("blur", fs.validateMessage);
    visitorMessage.addEventListener("change", fs.validateMessage);

    btnSend.addEventListener("click", function () {
      if (
        fs.nameErr === true ||
        fs.emailErr === true ||
        fs.reaErr === true ||
        fs.msgErr === true ||
        fs.phErr === true ||
        fs.comErr === true
      ) {
        swal({
          text: "Please check it!",
          icon: "img/warning.svg",
        });
        return false;
      } else {
        // ...Send To Email
        $.ajax({
          url: "js/ajax-php/send_contact.php",
          method: "POST",
          beforeSend: function () {
            $(".form-submit-indicator").css({
              visibility: "visible",
              opacity: 1,
            });
          },
          dataType: "json",
          data: $("#contactForm").serialize(),
          success: function (res) {
            if (res.errors) {
              for (let error in res.errors) {
                swal(res.errors[error]);
              }
            } else {
              if (res.success) {
                $(".form-submit-indicator").css("display", "none");
                swal("Sent", {
                  icon: "img/success.svg",
                });
                // Reset Form
                contactForm.reset();
                // Close Modal
                $("#contactFormModal").modal("hide");
              } else {
                swal("Something went wrong", {
                  icon: "img/error.svg",
                });
              }
            }
          },
        });
      }
    });
  }

  // $("#viTel").intlTelInput({
  //   initialCountry: "auto",
  //   geoIpLookup: function (callback) {
  //     $.get("https://ipinfo.io", function () {}, "jsonp").always(function (
  //       resp
  //     ) {
  //       var countryCode = resp && resp.country ? resp.country : "";
  //       callback(countryCode);
  //       console.log(countryCode);
  //     });
  //   },
  //   utilsScript: "../js/utils.js", // just for formatting/placeholders etc
  // });
});
