// Error vars
export let nameErr = true,
  emailErr = true,
  reqErr = true,
  reaErr = true,
  comErr = false,
  phErr = false,
  msgErr = true;

// Class attribute Resetter
export function resetClass(elem, className) {
  [...elem].forEach(function (e) {
    e.classList.remove(className);
  });
}

// Name Validator
export function validateName() {
  if (this.value.trim().length > 0) {
    const regex = /^[a-zA-Z\s]+$/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Name is invalid <i class='fas fa-exclamation-circle'></i>"
      );
      validate("nameErr", true);
    } else if (this.value.trim().length < 4) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Name is too short <i class='fas fa-exclamation-circle'></i>"
      );
      validate("nameErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("nameErr", false);
    }
  } else {
    this.style.borderColor = "#dc3545";
    displayError(
      this.nextElementSibling,
      "Name is required <i class='fas fa-exclamation-circle'></i>"
    );
    validate(nameErr, true);
  }
}

// Email Validator
export function validateEmail() {
  if (this.value.trim().length > 0) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Email is invalid <i class='fas fa-exclamation-circle'></i>"
      );
      validate("emailErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("emailErr", false);
    }
  } else {
    this.style.borderColor = "#dc3545";
    displayError(
      this.nextElementSibling,
      "Email is required <i class='fas fa-exclamation-circle'></i>"
    );
    validate("emailErr", true);
  }
}

// Request Validator
export function validateRequest() {
  if (this.value.trim().length > 0) {
    const regex = /\w+/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Invalid input <i class='fas fa-exclamation-circle'></i>"
      );
      validate("reqErr", true);
    } else if (this.value.trim().length < 20) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Please add a little more detail <i class='fas fa-exclamation-circle'></i>"
      );
      validate("reqErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("reqErr", false);
    }
  } else {
    this.style.borderColor = "#dc3545";
    displayError(
      this.nextElementSibling,
      "Please tell us what you Need <i class='fas fa-exclamation-circle'></i>"
    );
    validate("reqErr", true);
  }
}

// Reason Validator
export function validateReason() {
  if (this.value.trim().length > 0) {
    const regex = /\w+/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Invalid selection <i class='fas fa-exclamation-circle'></i>"
      );
      validate("reaErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("reaErr", false);
    }
  } else {
    this.style.borderColor = "#dc3545";
    displayError(
      this.nextElementSibling,
      "Subject is required <i class='fas fa-exclamation-circle'></i>"
    );
    validate("reaErr", true);
  }
}

// Company Name Validator
export function validateComName() {
  if (this.value.trim().length > 0) {
    const regex = /\w+/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Invalid input <i class='fas fa-exclamation-circle'></i>"
      );
      validate("comErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("comErr", false);
    }
  }
}

// Phone Validator
export function validatePhone() {
  if (this.value.trim().length > 0) {
    const regex = /^(\+\d{9,15})$/;
    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Invalid <i class='fas fa-exclamation-circle'></i>"
      );
      validate("phErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("phErr", false);
    }
  }
}

// Message Validator
export function validateMessage() {
  if (this.value.trim().length > 0) {
    const regex = /\w+/;

    if (!regex.test(this.value)) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Invalid input <i class='fas fa-exclamation-circle'></i>"
      );
      validate("msgErr", true);
    } else if (this.value.trim().length < 20) {
      this.style.borderColor = "#dc3545";
      displayError(
        this.nextElementSibling,
        "Too short <i class='fas fa-exclamation-circle'></i>"
      );
      validate("msgErr", true);
    } else {
      this.style.borderColor = "#2ecc71";
      displayError(this.nextElementSibling, "");
      validate("msgErr", false);
    }
  } else {
    this.style.borderColor = "#dc3545";
    displayError(
      this.nextElementSibling,
      "Message is required <i class='fas fa-exclamation-circle'></i>"
    );
    validate("msgErr", true);
  }
}

// Update error Vars
export function validate(errVar, bool) {
  switch (errVar) {
    case "nameErr":
      nameErr = bool;
      break;
    case "emailErr":
      emailErr = bool;
      break;
    case "reqErr":
      reqErr = bool;
      break;
    case "reaErr":
      reaErr = bool;
      break;
    case "comErr":
      comErr = bool;
      break;
    case "phErr":
      phErr = bool;
      break;
    case "msgErr":
      msgErr = bool;
      break;
  }
}

// Error Display Function
export function displayError(element, message) {
  if (element) {
    element.innerHTML = "<small>" + message + "</small>";
  }
}
