import * as fs from "./functions";

// Request Form
$(document).ready(function () {
  if (document.forms.requestForm) {
    const requestForm = document.forms.requestForm,
      visitorName = requestForm["visitor_name"],
      visitorEmail = requestForm["visitor_email"],
      visitorRequest = requestForm["visitor_request"],
      btnSend = requestForm["send_request"];

    // Prevent Default
    requestForm.addEventListener("submit", function (e) {
      e.preventDefault();
    });

    // BEGIN VALIDATION

    // Validate Name
    visitorName.addEventListener("blur", fs.validateName);
    visitorName.addEventListener("change", fs.validateName);

    // Validate Email
    visitorEmail.addEventListener("blur", fs.validateEmail);
    visitorEmail.addEventListener("change", fs.validateEmail);

    // Validate Request
    visitorRequest.addEventListener("blur", fs.validateRequest);
    visitorRequest.addEventListener("change", fs.validateRequest);

    btnSend.addEventListener("click", function () {
      if (fs.nameErr === true || fs.emailErr === true || fs.reqErr === true) {
        swal({
          text: "Please check it!",
          icon: "img/warning.svg",
        });
        return false;
      } else {
        // ...Send To Email
        const data = $("#requestForm").serialize();
        $.ajax({
          url: "js/ajax-php/send_request.php",
          beforeSend: function () {
            $(".form-submit-indicator").show();
          },
          method: "POST",
          dataType: "json",
          data: data,
          success: function (res) {
            if (res.errors) {
              for (let error in res.errors) {
                swal(res.errors[error]);
              }
            } else {
              if (res.success) {
                $(".form-submit-indicator").hide();
                swal("Sent", {
                  icon: "img/success.svg",
                });
                // Reset Form
                requestForm.reset();
                // Close Modal
                $("#requestFormModal").modal("hide");
              } else {
                swal("Something went wrong", {
                  icon: "img/error.svg",
                });
              }
            }
          },
        });
      }
    });
  }
});
