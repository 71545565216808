if (document.querySelector(".hero__carousel")) {
  $(".hero__carousel").slick({
    fade: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    infinite: true,
    autoplaySpeed: 4000,
    speed: 1000,
    swipe: true,
    touchMove: true,
    asNavFor: ".hero__carousel_nav",
    prevArrow:
      '<button class="arrow prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow:
      '<button class="arrow next"><i class="fa fa-angle-right"></i></button>',
    lazyLoad: "ondemand",
  });

  $(".hero__carousel_nav").slick({
    slidesToShow: 1,
    asNavFor: ".hero__carousel",
  });
}
