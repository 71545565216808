import swal from "sweetalert";
import { resetClass } from "./functions";

$(document).ready(function () {
  // Remove Hero Carousel bgImage
  setTimeout(function () {
    $(".hero__carousel").css("background-image", "none");
  }, 3500);

  // Sticky Menu
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 50) {
      $(".app-navbar").addClass("fixed-top");
    } else {
      $(".app-navbar").removeClass("fixed-top");
    }

    // Toggle back to Top Button
    if ($(this).scrollTop() > 400) {
      $("#back-to-top").slideDown(800);
    } else {
      $("#back-to-top").slideUp(800);
    }
  });

  // Mobile
  $(".navbar-toggler").click(function () {
    $(".app-navbar").toggleClass("fixed-top");
    let icon = $(this).find("span:only-child");
    if (icon.hasClass("fa-bars")) {
      icon.addClass("fa-times");
      icon.removeClass("fa-bars");
    } else {
      icon.addClass("fa-bars");
      icon.removeClass("fa-times");
    }
  });

  // Back to Top
  $("#back-to-top").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 1000, "easeInCirc");
    return false;
  });

  // On Scroll Animation
  if (document.querySelector("body#home")) {
    const tl = new TimelineMax(),
      tl2 = new TimelineMax();
    let i = 3;

    $(".product").each(function () {
      tl.from($(this), 1, { y: "15%", opacity: 0 }, i++ / 3.5);
    });

    tl2.from(".app-footer__widget", 1, { opacity: 0 });

    const controller = new ScrollMagic.Controller(),
      controller2 = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".products .product",
      reverse: false,
    })
      .setTween(tl)
      .addTo(controller);

    const scene2 = new ScrollMagic.Scene({
      triggerElement: ".app-footer",
      reverse: false,
    })
      .setTween(tl2)
      .addTo(controller2);
  }

  // Business Hour
  if (document.querySelector(".business-hour")) {
    let bhElem = $(".business-hour");
    bhElem.fadeIn(800);

    setInterval(function () {
      const tz = new Date().toLocaleString("en-US", {
        timeZone: "Africa/Lagos",
      });
      const date = new Date(tz);
      switch (date.getDay()) {
        case 0:
          bhElem.find("span:first-child").text("We Are Closed");
          break;
        case 1:
          if (date.getHours() >= 8 && date.getHours() < 9) {
            bhElem.find("span:first-child").text("Opening Soon");
          } else if (date.getHours() >= 9 && date.getHours() < 17) {
            bhElem.find("span:first-child").text("We Are Open");
          } else {
            bhElem.find("span:first-child").text("We Are Closed");
          }
          break;
        case 2:
          if (date.getHours() >= 8 && date.getHours() < 9) {
            bhElem.find("span:first-child").text("Opening Soon");
          } else if (date.getHours() >= 9 && date.getHours() < 17) {
            bhElem.find("span:first-child").text("We Are Open");
          } else {
            bhElem.find("span:first-child").text("We Are Closed");
          }
          break;
        case 3:
          if (date.getHours() >= 8 && date.getHours() < 9) {
            bhElem.find("span:first-child").text("Opening Soon");
          } else if (date.getHours() >= 9 && date.getHours() < 17) {
            bhElem.find("span:first-child").text("We Are Open");
          } else {
            bhElem.find("span:first-child").text("We Are Closed");
          }
          break;
        case 4:
          if (date.getHours() >= 8 && date.getHours() < 10) {
            bhElem.find("span:first-child").text("Opening Soon");
          } else if (date.getHours() >= 10 && date.getHours() < 17) {
            bhElem.find("span:first-child").text("We Are Open");
          } else {
            bhElem.find("span:first-child").text("We Are Closed");
          }
          break;
        case 5:
          if (date.getHours() >= 8 && date.getHours() < 9) {
            bhElem.find("span:first-child").text("Opening Soon");
          } else if (date.getHours() >= 9 && date.getHours() < 17) {
            bhElem.find("span:first-child").text("We Are Open");
          } else {
            bhElem.find("span:first-child").text("We Are Closed");
          }
          break;
        case 6:
          bhElem.find("span:first-child").text("We Are Closed");
          break;
        default:
          bhElem.find("span:first-child").text("Time");
      }
    }, 1000);

    bhElem.click(function () {
      let cTime = document.createElement("time");
      cTime.innerHTML = "00:00:00";

      setInterval(function () {
        cTime.innerHTML = new Date().toLocaleTimeString("en-US", {
          timeZone: "Africa/Lagos",
        });
      }, 1000);

      swal({
        title: new Date().toLocaleDateString("en-US", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
          timeZone: "Africa/Lagos",
        }),
        content: cTime,
        icon: "img/info.svg",
      });
    });
  }

  // WhatsApp Widget
  if (
    document.querySelector(
      "#app-widget__whatsApp, button[data-target='#app-widget__whatsApp']"
    )
  ) {
    $(
      "#app-widget__whatsApp > button, button[data-target='#app-widget__whatsApp']"
    ).click(function () {
      swal({
        button: {
          text: "Send",
        },
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter your message",
            type: "text",
            autocomplete: "off",
          },
        },
      }).then(function (value) {
        if (value.trim().length > 0) {
          window.open(
            "https://api.whatsapp.com/send?phone=+2347060531909&text=" +
              value +
              "",
            "_blank"
          );
        } else {
          swal("Please enter a message");
        }
      });
    });
  }

  // App Accordion
  const accordionLink = $(".app-accordion .card-header");

  accordionLink.each(function () {
    $(this).on({
      click: function (e) {
        // Reset active class
        resetClass(accordionLink, "active");
        // Add active class to the Current Target
        e.currentTarget.classList.add("active");
        // Link
        let theLink = e.currentTarget.lastElementChild.lastElementChild;
        if (theLink.classList.contains("fa-plus-circle")) {
          theLink.classList.add("fa-minus-circle");
          theLink.classList.remove("fa-plus-circle");
        } else {
          theLink.classList.add("fa-plus-circle");
          theLink.classList.remove("fa-minus-circle");
        }
      },
      mouseleave: function (e) {
        let theLink = e.currentTarget.lastElementChild.lastElementChild;
        if (theLink.classList.contains("fa-minus-circle")) {
          theLink.classList.add("fa-plus-circle");
          theLink.classList.remove("fa-minus-circle");
        }
      },
    });
  });
  $(".app-copyright-text #year").text(new Date().getFullYear());

  // Cookie Consent
  $(".privacy-policy-notice button").click(function () {
    document.cookie = "herma_cookieconsent_status=dismiss";
    $(".privacy-policy-notice").fadeOut("slow");
  });

  let cookies = document.cookie;
  cookies = cookies.split(";");
  cookies.forEach(function (cookie) {
    let x = cookie.slice(cookie.indexOf("=") + 1, cookie.length);
    if (x === "dismiss") {
      $(".privacy-policy-notice").hide();
    }
  });
});

// Preloader
document.addEventListener("readystatechange", function () {
  if (this.readyState === "complete") {
    $(".app-preloader").delay(3000).fadeOut(2000);
  }
});
